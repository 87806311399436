<template>
  <b-modal
    visible
    scrollable
    centered
    size="s"
    :title="'Загрузка анализа'"
    @hidden="onClose"
    @ok="onClose"
  >
    <loading v-if="isLoading">
      Загрузка данных
    </loading>
    <div v-else>
      <b-row>
        <b-col>
          <b-form-file
            v-model="importFile"
            plain
          />
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          v-if="analysisId"
          class="d-flex float-left"
          variant="outline-primary"
          :type="$const.PRIMARY_BUTTON"
          @click="downloadOrderAnalysis"
        >
          <b-icon
            icon="file-earmark-fill"
          />Скачать
        </b-button>
        <div class="float-right">
          <b-button
            class="mr-2"
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            :disabled="!importFile"
            @click="onClickImport"
          >
            Загрузить
          </b-button>
          <b-button
            variant="danger"
            :type="$const.PRIMARY_BUTTON"
            @click="onClose"
          >
            Отмена
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Loading from '@/components/Loading';

export default {
  name: 'UploadOrderAnalysis',
  components: {
    Loading,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    fileName: {
      type: [String, Number],
      default: null,
    },
    orderItemId: {
      type: [String, Number],
      default: null,
    },
    analysisId: {
      type: [String, Number],
      default: null,
    },
    orderId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      importFile: null,
    };
  },
  computed: {
    legalPartnerListStore() {
      return this.$store.state.Laboratories.legalPartnerList;
    },
  },
  methods: {
    async onClose() {
      this.$emit('input', false);
    },
    async onClickImport() {
      const params = {
        orderItemId: this.orderItemId,
        importFile: this.importFile,
      };
      await this.$store.dispatch(this.$types.LABORATORY_ORDER_UPLOAD_ANALYSIS, params);
      await this.$store.dispatch(this.$types.GET_LABORATORY_ORDER_FOR_ID, {
        orderId: this.orderId,
        save: true,
      });
      this.onClose();
    },
    async downloadOrderAnalysis() {
      await this.$store.dispatch(this.$types.GET_LABORATORY_ORDER_DOWNLOAD_ANALYSIS,
        { analysisId: this.analysisId, fileName: this.fileName });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
